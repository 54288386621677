import React from 'react'
import moment from 'moment'
import { Row, Col, Form, Modal } from 'react-bootstrap'

// Global reducer
import {
  useAlert,
  useQuery,
  useState,
  useHistory,
  useGlobal,
  useMutation,
} from '../../../../../../hooks'

// Components
import UIButton from '../../../../../../components/UI/Button'
import UIForm from '../../../../../../components/UI/FormReload'
import UIFormSeparation from '../../../../../../components/UI/FormReload/Separation'
import UIFormInputDate from '../../../../../../components/UI/FormReload/Input/Date'
import UITable from '../../../../../../components/UI/Table'
import UITableHead from '../../../../../../components/UI/Table/Head'
import UITableBody from '../../../../../../components/UI/Table/Body'
import UIPanelInfo from '../../../../../../components/UI/Panel/Info'
import UIPanelInfoBlock from '../../../../../../components/UI/Panel/Info/Block'
import UIPanelInfoItem from '../../../../../../components/UI/Panel/Info/Item'
import UILoading from '../../../../../UI/Loading'
import UIError from '../../../../../UI/Error'

// Queries
import {
  LIST_MASSIVE_COMPONENTS,
  ENTER_MASSIVE_COMPONENTS,
  ENTER_RECEIVE_MASSIVE_COMPONENTS,
  ARCHIVE_MASSIVE_SAMPLES,
  ELIMINATE_MASSIVE_SAMPLES,
} from './queries'

const SampleFormInsertMassiveTabWebTable = ({ id }) => {
  const alert = useAlert()

  const history = useHistory()

  const [checkAll, setCheckAllState] = useState(false)

  const [active, setActiveState] = useState({})

  // --------------------------------------------------------------------------
  //
  // Mutation: Insert samples
  //
  // --------------------------------------------------------------------------

  const { data, loading, refetch } = useQuery(LIST_MASSIVE_COMPONENTS, {
    fetchPolicy: 'network-only',
    variables: { id_massive: id },
    onSuccess: ({ data }) => {
      setActiveState(
        data.components.reduce(
          (p, c) => ({ ...p, [c.id]: [1, '1'].includes(c.id_stage) }),
          {},
        ),
      )
    },
    onErrorMessage: `No se pudo obtener la lista de muestras.`,
  })

  // --------------------------------------------------------------------------
  //
  // Mutation: Insert samples
  //
  // --------------------------------------------------------------------------

  // const [enterMassiveComponents] = useMutation(ENTER_MASSIVE_COMPONENTS, {
  //   onSuccessMessage: 'Las muestras fueron ingresadas y recibidas con éxito',
  //   onSuccess: () => {
  //     refetch()
  //   },
  //   onErrorMessage: `La muestra no pudo ser ingresada y recibida`,
  // })

  // --------------------------------------------------------------------------
  //
  // Mutation: Insert samples
  //
  // --------------------------------------------------------------------------

  const [enterReceiveMassiveComponents] = useMutation(
    ENTER_RECEIVE_MASSIVE_COMPONENTS,
    {
      onSuccessMessage: 'Las muestras fueron ingresadas con éxito',
      onSuccess: ({ data }) => {
        refetch()

        let text = ''

        for (const label of data) {
          const { sample, component, lubricant, client } = label

          text = `${text}ON
Q383,16
q799
S3
D10
ZT
JF
oR

N
I8,A,001
A33,131,0,1,1,2,N,"Número Muestra:"
A33,201,0,1,1,2,N,"Lubricante:  "
A435,77,0,1,2,2,N,"${sample.priority}"
B640,353,3,E30,3,0,117,B,"${'0'.repeat(
            12 - sample.id.toString().length,
          )}${sample.id.toString()}"
A34,269,0,1,1,2,N,"Cliente:"
A209,124,0,1,2,3,N,"${sample.id}"
A33,167,0,1,1,2,N,"TAG:  "
A208,160,0,1,2,3,N,"${component.tag}"
A455,32,0,1,1,2,N,"${sample.date}"
A209,228,0,1,2,3,N,""
A211,272,0,3,1,1,N,"${client}"
A211,293,0,3,1,1,N,""
A209,194,0,1,2,3,N,"${lubricant.name}"
P1

`
        }

        const now = new Date()
        const fileName = `Impresion_dia_${now.getDate()}_hora_${Math.round(
          now.getTime() / 1000,
        )}.txt`

        const element = document.createElement('a')
        const file = new Blob([text], {
          type: 'text/plain;charset=utf-8',
        })
        element.href = URL.createObjectURL(file)
        element.download = fileName
        document.body.appendChild(element)
        element.click()
        // Get data with info for labels
      },
      onErrorMessage: `La muestra no pudo ser ingresada`,
    },
  )

  // --------------------------------------------------------------------------
  //
  // Mutation: Insert samples
  //
  // --------------------------------------------------------------------------

  const [archiveMassiveSamples] = useMutation(ARCHIVE_MASSIVE_SAMPLES, {
    onSuccessMessage: 'Las muestras no ingresadas fueron archivadas con éxito',
    onSuccess: () => {
      history.push(`/tribology/samples/insert/iscaa`)
    },
    onErrorMessage: `La muestra no pudo ser ingresada`,
  })

  // --------------------------------------------------------------------------
  //
  // Mutation: Insert samples
  //
  // --------------------------------------------------------------------------

  const [eliminateMassiveSamples] = useMutation(ELIMINATE_MASSIVE_SAMPLES, {
    onSuccessMessage: 'Las muestras no archivadas fueron eliminadas con éxito',
    onSuccess: () => {
      history.push(`/tribology/samples/insert/iscaa`)
    },
    onErrorMessage: `La muestra no pudo ser eliminada`,
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  // Loading
  if (loading) return <UILoading />

  if (
    data &&
    data.t1MassiveIscaaComponents &&
    data.t1MassiveIscaaComponents.data
  ) {
    const { info, components } = data.t1MassiveIscaaComponents.data

    const handleSubmit = ({ date_withdraw }) => {
      enterReceiveMassiveComponents({
        variables: {
          date_withdraw: moment(date_withdraw).format('YYYY-MM-DD HH:MM:00'),
          id_massive: id,
          ids: components
            .filter(
              ({ id, stage_id }) => active[id] && [1, '1'].includes(stage_id),
            )
            .map(({ id }) => id),
        },
      })
    }

    const handleBack = () => {
      history.push(`/tribology/samples/insert/iscaa`)
    }

    const handleActiveChange = (event, index) => {
      setActiveState((prev) => {
        prev[index] = event.target.checked
        return { ...prev }
      })
    }

    const classNameHeader = 'bg-stng border text-white align-middle'

    const handleCheckAll = () => {
      setCheckAllState((prev) => {
        setActiveState(
          Object.keys(active).reduce((p, c) => ({ ...p, [c]: !prev }), {}),
        )
        return !prev
      })
    }

    const handleArchive = () => {
      archiveMassiveSamples({ variables: { id_massive: id } })
    }

    const handleEliminate = () => {
      eliminateMassiveSamples({ variables: { id_massive: id } })
    }

    const classes = 'm-0 p-0'
    const styles = { fontSize: '12px' }

    return (
      <>
        <UIPanelInfo>
          <Row>
            <Col md={3}>
              <UIPanelInfoBlock className="my-0 ml-2">
                <UIPanelInfoItem
                  name="Faena"
                  className={classes}
                  style={styles}
                >
                  {info.site_name}
                </UIPanelInfoItem>
                <UIPanelInfoItem
                  name="Fecha creación"
                  className={classes}
                  style={styles}
                >
                  {moment(info.date_created).format('DD/MM/YYYY')}
                </UIPanelInfoItem>
                <UIPanelInfoItem
                  name="Estado"
                  className={classes}
                  style={styles}
                >
                  {info.stage_name}
                </UIPanelInfoItem>
              </UIPanelInfoBlock>
            </Col>
          </Row>
        </UIPanelInfo>
        <UIForm onSubmit={handleSubmit}>
          <Form.Group as={Row} className="my-4">
            <Col className="d-flex">
              <UIButton className="mx-2" onClick={handleBack}>
                Atrás
              </UIButton>
              {/*
              <UIButton className="mx-2" type="submit">
                Ingresar muestras
              </UIButton>
              */}
              <UIButton className="mx-2" type="submit">
                Ingresar y recibir muestras
              </UIButton>
              <UIButton
                className="ml-auto"
                variant="danger"
                onClick={
                  [1, '1'].includes(info.stage_id)
                    ? handleArchive
                    : handleEliminate
                }
              >
                {[1, '1'].includes(info.stage_id) ? 'Archivar' : 'Eliminar'}
              </UIButton>
            </Col>
          </Form.Group>
          <UIFormSeparation />
          <UITable borderless className="text-center font-weight-light my-4">
            <UITableHead>
              <tr>
                {[
                  'Act.',
                  'Estado',
                  'Tipo',
                  'Tag Komatsu',
                  'Tag',
                  'ISCAA',
                  'Descripción',
                  'Equipo',
                  'Tipo de equipo',
                ].map((name, index) => (
                  <td key={`td-${index}`} className={classNameHeader}>
                    <small>{name}</small>
                  </td>
                ))}
              </tr>
            </UITableHead>
            <UITableBody>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    onChange={handleCheckAll}
                    checked={checkAll}
                  />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {components.map(
                (
                  {
                    tag,
                    tag_komatsu,
                    sample_type,
                    component_name,
                    machine_name,
                    machine_type_name,
                    fluid_unique_number,
                    stage_id,
                    stage_name,
                    id,
                  },
                  component_index,
                ) => (
                  <tr key={`component_${component_index}`}>
                    <td>
                      <input
                        type="checkbox"
                        disabled={[2, '2'].includes(stage_id)}
                        checked={[2, '2'].includes(stage_id) || active[id]}
                        onChange={(event) => {
                          handleActiveChange(event, id)
                        }}
                      />
                    </td>
                    <td>
                      <small>{stage_name}</small>
                    </td>
                    <td>
                      <small>{sample_type}</small>
                    </td>
                    <td>
                      <small>{tag_komatsu}</small>
                    </td>
                    <td>
                      <small>{tag}</small>
                    </td>
                    <td>
                      <small>{fluid_unique_number}</small>
                    </td>
                    <td>
                      <small>{component_name}</small>
                    </td>
                    <td>
                      <small>{machine_name}</small>
                    </td>
                    <td>
                      <small>{machine_type_name}</small>
                    </td>
                  </tr>
                ),
              )}
              <UIFormInputDate
                required
                showTime
                label="Fecha de retiro"
                name="date_withdraw"
              />
            </UITableBody>
          </UITable>
        </UIForm>
      </>
    )
  }

  // Error
  return <UIError />
}

export default SampleFormInsertMassiveTabWebTable
