import React from 'react'
import {
  Badge,
  Card,
  Container,
  Row,
  Col,
  Image,
  InputGroup,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faImage,
  faFilePdf,
  faEdit,
  faSave,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'

// Components
import UILoading from '../../../UI/Loading'
import UIError from '../../../UI/Error'
import UITable from '../../../UI/Table'
import UITableBody from '../../../UI/Table/Body'
import UITableHead from '../../../UI/Table/Head'
import UIForm from '../../../UI/FormReload'
import UIFormInputSelect from '../../../UI/FormReload/Input/Select'
import UIFormInputText from '../../../UI/FormReload/Input/Text'
import UIFormInputTextarea from '../../../UI/FormReload/Input/Textarea'
import UIButton from '../../../UI/Button'
import UIModal from '../../../UI/Modal'

// Global reducer
import {
  useMutation,
  useQuery,
  useGlobal,
  useRef,
  useState,
  useHistory,
  useTrigger,
  useEffect,
} from '../../../../hooks'

// Queries
import {
  LIST_INSPECTIONS_ENTRIES,
  PUT_INSPECTION_PHOTOS,
  LIST_CRITICALITIES,
  PUT_INSPECTION_FILES,
  UPDATE_INSPECTION,
  GENERATE_REPORT,
  DELETE_PHOTO,
} from './queries'

import config from '../../../../config'
import { onError } from 'apollo-link-error'

const { AWS } = config

const AVOTInput = ({
  id,
  currentInspection,
  setCurrentInspectionState,
  ...props
}) => {
  const handleClick = () => {
    setCurrentInspectionState((prev) => ({
      ...prev,
      av_ots: [...prev.av_ots, { value: '', type: 'AV' }],
    }))
  }

  const handleToggleAVOT = (index) => {
    setCurrentInspectionState((prev) => {
      prev.av_ots[index].type = prev.av_ots[index].type === 'AV' ? 'OT' : 'AV'
      return {
        ...prev,
      }
    })
  }

  const handleBlur = (event, index) => {
    setCurrentInspectionState((prev) => {
      prev.av_ots[index].value = event.target.value
      return {
        ...prev,
        av_ots: prev.av_ots.filter(({ value }) => value !== ''),
      }
    })
  }

  return (
    <>
      {currentInspection.av_ots.map((avot, index) => (
        <UIFormInputText
          key={`${id}-${index}`}
          {...props}
          className="mb-1"
          name={`${id}-${index}`}
          onBlur={(event) => handleBlur(event, index)}
          value={avot.value}
          prepend={
            <InputGroup.Text
              className="mb-1"
              style={{ width: '40px', cursor: 'pointer' }}
              onClick={() => !props.disabled && handleToggleAVOT(index)}
            >
              {avot.type}
            </InputGroup.Text>
          }
        />
      ))}
      {!props.disabled && (
        <UIButton
          variant="outline-stng"
          className="px-3 font-weight-light"
          onClick={handleClick}
        >
          +
        </UIButton>
      )}
    </>
  )
}

// const UploadFile = ({ onNewFiles, disabled, ...props }) => {
//   const ref = useRef()
//
//   const handleChange = (event) => {
//     if (event.target.files && event.target.files.length > 0) {
//       onNewFiles(event.target.files)
//     }
//   }
//
//   const handleClick = () => {
//     ref.current.click()
//   }
//
//   return (
//     <>
//       <input
//         {...props}
//         multiple
//         type="file"
//         ref={ref}
//         className="d-none"
//         onChange={handleChange}
//         accept="application/pdf"
//       />
//       <UIButton
//         variant="outline-stng"
//         className="font-weight-light d-block"
//         style={{ width: '40px' }}
//         onClick={handleClick}
//         disabled={disabled}
//       >
//         <FontAwesomeIcon size="sm" icon={faFilePdf} />
//       </UIButton>
//     </>
//   )
// }

const UploadImage = ({ onNewImages, disabled, ...props }) => {
  const ref = useRef()

  const handleChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      onNewImages(event.target.files)
    }
  }

  const handleClick = () => {
    ref.current.click()
  }

  return (
    <>
      <input
        {...props}
        multiple
        type="file"
        ref={ref}
        className="d-none"
        onChange={handleChange}
        accept="image/*"
      />
      <UIButton
        variant="outline-stng"
        className="font-weight-light d-block mb-1"
        style={{ width: '40px' }}
        onClick={handleClick}
        disabled={disabled}
      >
        <FontAwesomeIcon size="sm" icon={faImage} />
      </UIButton>
    </>
  )
}

const Photo = ({ isEdit, id_inspection, id, name, deletePhoto }) => {
  const [{ me }] = useGlobal()

  const [modalDeleteTrigger, setModalDeleteTrigger] = useTrigger()

  const handleDeletePhoto = () => {
    setModalDeleteTrigger()
  }

  const handleAcceptDeleteModal = () => {
    // setApiKeyState('')
    deletePhoto({ variables: { id } })
  }

  return (
    <Col md={4}>
      <Image
        src={`https://s3-sa-east-1.amazonaws.com/${AWS.BUCKET}/${me.laboratory}/inspections/photos/${id_inspection}/${name}`}
        thumbnail
      />
      {isEdit && (
        <>
          <UIButton
            variant="danger"
            className="font-weight-light d-block my-1"
            onClick={handleDeletePhoto}
          >
            <FontAwesomeIcon size="1x" icon={faTimes} /> Eliminar
          </UIButton>
          <UIModal
            trigger={modalDeleteTrigger}
            onAccept={handleAcceptDeleteModal}
            head={'Confirmación'}
            body={<>¿Está seguro que desea eliminar la imagen?</>}
          />
        </>
      )}
    </Col>
  )
}

const Inspection = ({ id_inspection, inspection, onEditing }) => {
  const [{ me }] = useGlobal()

  const [isEdit, setIsEditState] = useState(false)
  const [isLoadingImages, setIsLoadingImagesState] = useState(false)
  const [isLoadingFiles, setIsLoadingFilesState] = useState(false)

  const [originalInspection, setOriginalInspectionState] = useState(inspection)
  const [currentInspection, setCurrentInspectionState] = useState(inspection)

  useEffect(() => {
    onEditing(isEdit)
  }, [isEdit])

  // Put file into S3
  // const [putInspectionFiles] = useMutation(PUT_INSPECTION_FILES, {
  //   fetchPolicy: 'no-cache',
  //   onSuccess: ({ data }) => {
  //     setCurrentInspectionState((prev) => ({
  //       ...prev,
  //       files: [...prev.files, ...data],
  //     }))
  //     setIsLoadingFilesState(false)
  //   },
  //   onError: (error) => {
  //     console.log('error putting files', error)
  //     setIsLoadingFilesState(false)
  //   },
  // })

  // Put images into S3
  const [putInspectionPhotos] = useMutation(PUT_INSPECTION_PHOTOS, {
    fetchPolicy: 'no-cache',
    onSuccess: ({ data }) => {
      setCurrentInspectionState((prev) => ({
        ...prev,
        photos: [...prev.photos, ...data],
      }))
      setIsLoadingImagesState(false)
    },
    onError: (error) => {
      console.log('error putting photos', error)
      setIsLoadingImagesState(false)
    },
  })

  const [updateInspection] = useMutation(UPDATE_INSPECTION, {
    fetchPolicy: 'no-cache',
    onSuccess: ({ data }) => {
      setOriginalInspectionState(currentInspection)
      setIsEditState(false)
    },
    onError: (error) => {
      console.log('error putting photos', error)
      setIsEditState(false)
    },
  })

  const [deletePhoto] = useMutation(DELETE_PHOTO, {
    fetchPolicy: 'no-cache',
    onSuccess: ({ data }) => {
      // My god ...
      setOriginalInspectionState((prev) => ({
        ...prev,
        photos: prev.photos.filter(({ id }) => id != parseInt(data.id)),
      }))
      setCurrentInspectionState((prev) => ({
        ...prev,
        photos: prev.photos.filter(({ id }) => id != parseInt(data.id)),
      }))
    },
    onError: (error) => {},
  })

  const handleEdit = () => {
    setCurrentInspectionState(originalInspection)
    setIsEditState((prev) => !prev)
  }

  const handleClose = () => {
    setIsEditState(false)
  }

  const handleSave = () => {
    const { id, observation, id_criticality, photos, files, av_ots } =
      currentInspection
    updateInspection({
      variables: {
        id_inspection: id,
        observation,
        id_criticality,
        photos: photos.filter(({ id }) => !id).map(({ name }) => name),
        files: files.filter(({ id }) => !id).map(({ name }) => name),
        av_ots: av_ots.map(({ value, type }) => ({ value, type })),
      },
    })
  }

  const handleNewImages = (images) => {
    putInspectionPhotos({ variables: { photos: images, id_inspection } })
    setIsLoadingImagesState(true)
  }

  // const handleNewFiles = (files) => {
  //   putInspectionFiles({ variables: { files, id_inspection } })
  //   setIsLoadingFilesState(true)
  // }

  return (
    <>
      <tr>
        <td
          className={`pt-3 ${isEdit ? '' : 'text-muted'}`}
          style={{ width: '150px' }}
        >
          <h6>{currentInspection.system.name}</h6>
          <p>
            <strong>
              {currentInspection.subsystem.name || <i>-- Sin subsistema --</i>}{' '}
            </strong>
          </p>
        </td>
        <td>
          <UIFormInputTextarea
            name="observation"
            value={currentInspection.observation}
            onChange={(event) =>
              setCurrentInspectionState((prev) => ({
                ...prev,
                observation: event.target.value,
              }))
            }
            minRows={3}
            disabled={!isEdit}
          />
        </td>
        <td style={{ width: '200px' }}>
          <UIFormInputSelect
            name="id_criticality"
            query={LIST_CRITICALITIES}
            value={currentInspection.id_criticality}
            onChange={(event) =>
              setCurrentInspectionState((prev) => ({
                ...prev,
                id_criticality: event.target.value,
              }))
            }
            disabled={!isEdit}
          />
        </td>
        <td style={{ width: '150px' }}>
          <AVOTInput
            id={currentInspection.id}
            disabled={!isEdit}
            currentInspection={currentInspection}
            setCurrentInspectionState={setCurrentInspectionState}
          />
        </td>
        <td>
          <UIButton
            variant="stng"
            className="font-weight-light d-block mb-1"
            style={{ width: '40px' }}
            onClick={handleEdit}
            disabled={isLoadingImages || isLoadingFiles}
          >
            <FontAwesomeIcon size="sm" icon={faEdit} />
          </UIButton>
          {isEdit && (
            <>
              <UIButton
                variant="success"
                className="font-weight-light d-block mb-1"
                style={{ width: '40px' }}
                onClick={handleSave}
                disabled={isLoadingImages || isLoadingFiles}
              >
                <FontAwesomeIcon size="sm" icon={faSave} />
              </UIButton>
              <UIButton
                variant="danger"
                className="font-weight-light d-block mb-1"
                style={{ width: '40px' }}
                onClick={handleClose}
                disabled={isLoadingImages || isLoadingFiles}
              >
                <FontAwesomeIcon size="sm" icon={faTimes} />
              </UIButton>
              <UploadImage
                onNewImages={handleNewImages}
                disabled={isLoadingImages || isLoadingFiles}
              />
              {/*<UploadFile
                onNewFiles={handleNewFiles}
                disabled={isLoadingImages || isLoadingFiles}
              />*/}
            </>
          )}
        </td>
        {/*<td>
                    {inspection.date_creation &&
                      moment(inspection.date_created).format('DD/MM/YYYY')}
                  </td>*/}
      </tr>
      {currentInspection.photos.length > 0 && (
        <tr>
          {/*<tr className={index % 2 === 0 && 'table-active'}>*/}
          <td colSpan="4">
            <Container className="p-2">
              <Row>
                {currentInspection.photos.map((photo) => (
                  <Photo
                    {...photo}
                    isEdit={isEdit}
                    id_inspection={id_inspection}
                    deletePhoto={deletePhoto}
                  />
                ))}
              </Row>
            </Container>
          </td>
          <td></td>
        </tr>
      )}
      {currentInspection.files.length > 0 && (
        <tr>
          <td colSpan="4">
            <Container className="p-2">
              <Row>
                {currentInspection.files.map(({ name }) => {
                  return (
                    <Col md={6}>
                      <object
                        width="100%"
                        height="200"
                        data={`https://s3-sa-east-1.amazonaws.com/${AWS.BUCKET}/${me.laboratory}/inspections/files/${id_inspection}/${name}`}
                        type="application/pdf"
                      />
                    </Col>
                  )
                })}
              </Row>
            </Container>
          </td>
        </tr>
      )}
    </>
  )
}

const InspectionsDesktopForm = ({ id }) => {
  // --------------------------------------------------------------------------
  //
  // Query get sidepanel info
  //
  // --------------------------------------------------------------------------
  const history = useHistory()

  const [isEditing, setIsEditingState] = useState([])

  const { data, loading } = useQuery(LIST_INSPECTIONS_ENTRIES, {
    variables: { id },
    onErrorMessage: `Hubo un error y no se pudo obtener la lista de inspecciones`,
  })

  const [generateReport] = useMutation(GENERATE_REPORT, {
    fetchPolicy: 'no-cache',
    onSuccess: ({ data }) => {
      history.push(`/inspections/report/${id}`)
    },
  })

  if (loading) return <UILoading />

  if (data && data.i2ListInspectionsEntries) {
    const { inspections } = data.i2ListInspectionsEntries.data

    const handleEditing = (index) => {
      return (value) =>
        setIsEditingState((prev) => {
          prev[index] = value
          return [...prev]
        })
    }

    return (
      <UIForm>
        <Row className="mb-4">
          <Col className="text-left">
            <Link
              className={`text-decoration-none text-stng-darker`}
              to={`/inspections/desktop`}
            >
              <UIButton className="mx-2 font-weight-light" onClick={() => {}}>
                Volver
              </UIButton>
            </Link>
            <Badge
              className="px-2 py-2"
              variant="secondary"
              style={{ fontSize: '11pt' }}
            >
              Total observaciones: {inspections.length}
            </Badge>
          </Col>
          <Col className="text-right">
            <UIButton
              variant="stng"
              className="font-weight-light"
              onClick={() => {
                generateReport({ variables: { id } })
              }}
              disabled={isEditing.find((e) => !!e)}
            >
              Generar reporte
            </UIButton>
          </Col>
        </Row>
        <UITable striped={false}>
          <UITableHead>
            {['Sistema', 'Observación', 'Criticidad', 'AV/OT', 'Acción']}
          </UITableHead>
          <UITableBody>
            {inspections.map((inspection, index) => (
              <Inspection
                id_inspection={id}
                inspection={inspection}
                onEditing={handleEditing(index)}
              />
            ))}
          </UITableBody>
        </UITable>
      </UIForm>
    )
  }

  return <UIError />
}

export default InspectionsDesktopForm
