import { gql } from '../../../../hooks'

export const LIST_CRITICALITIES = gql`
  query ListCriticalities {
    i1ListCriticalities {
    }
  }
`

export const LIST_TECHNIQUES = gql`
  query ListTechniques($id_inspection: ID!) {
    i1TechniquesByInspection(id_inspection: $id_inspection) {
    }
  }
`

export const LIST_SYSTEMS = gql`
  query ListSystems($id_inspection: ID!, $id_technique: ID!) {
    i1Systems(id_inspection: $id_inspection, id_technique: $id_technique) {
    }
  }
`

export const LIST_SUB_SYSTEMS = gql`
  query ListSubSystems($id_system: ID!, $id_inspection: ID!, $id_technique: ID!) {
    i1SubSystems(id_system: $id_system, id_inspection: $id_inspection, id_technique: $id_technique) {
    }
  }
`

export const ADD_INSPECTION_ENTRY = gql`
  mutation AddInspectionEntry( $id_technique: ID!,  $id_inspection: ID!, $observation: String, $id_system: ID!, $id_sub_system: ID, $id_criticality: ID!, $photos: [ID!]!) {
    i1AddInspectionEntry(
      id_inspection: $id_inspection,
      observation: $observation,
      id_system: $id_system,
      id_sub_system: $id_sub_system,
      id_criticality: $id_criticality,
      id_technique: $id_technique,
      photos: $photos) {
    }
  }
`

export const PUT_INSPECTION_PHOTO = gql`
  mutation PutInspectionPhoto($photo: String!, $id_inspection: ID!) {
    i1PutInspectionPhoto(id_inspection: $id_inspection, photo: $photo) {
    }
  }
`

export const SPEECH_TO_TEXT = gql`
  mutation SpeechToText($id_inspection: ID!, $file: Upload!) {
    i1SpeechToText(id_inspection: $id_inspection, file: $file) {
    }
  }
`

export const DELETE_PHOTO = gql`
  mutation DeletePhoto($id: ID!) {
    i2DeletePhoto(id: $id) {
    }
  }
`
