import React from 'react'
import { withApollo } from 'react-apollo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import {
  Navbar,
  NavDropdown,
  Nav,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'

// Queries
import {
  GET_CLIENTS_BY_LABORATORY,
  GET_LABORATORIES,
  SIGN_OUT,
  UPDATE_CLIENT,
  UPDATE_LABORATORY,
} from './queries'

// Components
import UILoading from '../../../../../UI/Loading'

// Global reducer
import {
  useHistory,
  useGlobal,
  useQuery,
  useMutation,
} from '../../../../../../hooks'

// -------------------------------------------------------------------------
//
// Getting laboratotries
//
// -------------------------------------------------------------------------

const LaboratoryDropdown = ({ me, dispatch }) => {
  const { data, loading } = useQuery(GET_LABORATORIES)

  const [updateLaboratory] = useMutation(UPDATE_LABORATORY, {
    onCompleted: (data) => {
      if (data && data.updateUserLaboratory && data.updateUserLaboratory.data) {
        dispatch({
          type: 'updateLaboratory',
          id: data.updateUserLaboratory.data.id,
          value: data.updateUserLaboratory.data.value,
        })
      }
    },
  })

  // Render
  // ------------------------------------

  if (loading)
    return (
      <Navbar.Text>
        <UILoading />
      </Navbar.Text>
    )

  if (data && data.laboratories) {
    const handleUpdateLaboratory = (id) => {
      updateLaboratory({ variables: { id } })
    }

    const { name } = data.laboratories.data.filter(
      ({ id, name }) => id === parseInt(me.id_laboratory),
    )[0]

    switch (me.id_role) {
      // Root
      case 1:
      case 2:
      // Analista
      case 3:
      // Ingeniero
      case 4:
        return (
          <>
            <Navbar.Text>Laboratorio:</Navbar.Text>
            <NavDropdown
              title={<strong>{name}</strong>}
              id="basic-nav-dropdown"
              className="mr-3"
            >
              {data.laboratories.data.map(({ id, name }, index) => (
                <NavDropdown.Item
                  key={index}
                  onClick={() => handleUpdateLaboratory(id)}
                  className="my-0 py-0"
                >
                  <small>{name}</small>
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </>
        )
      // Cliente
      case 5:
      // Muestrista
      case 6:
      // Muestrista + cliente
      case 7:
      // Buscador de muestras
      case 8:
      // Inspector
      case 9:
      // Teck dashboard
      case 10:
        return (
          <>
            <Navbar.Text>Laboratorio:</Navbar.Text>
            <Navbar.Text className="mr-3 ml-1">
              <strong>{name}</strong>
            </Navbar.Text>
          </>
        )
      default:
        return <Navbar.Text>error</Navbar.Text>
    }
  }

  return <Navbar.Text>error</Navbar.Text>
}

// -------------------------------------------------------------------------
//
// Getting clients
//
// -------------------------------------------------------------------------

const ClientDropdown = ({ me, dispatch }) => {
  const { data, loading } = useQuery(GET_CLIENTS_BY_LABORATORY, {
    fetchPolicy: 'network-only',
    variables: { id: me.id_laboratory },
  })

  const [updateClient] = useMutation(UPDATE_CLIENT, {
    onCompleted: (data) => {
      if (data && data.updateUserClient && data.updateUserClient.data) {
        dispatch({ type: 'updateClient', id: data.updateUserClient.data })
      }
    },
  })

  // Render
  // ------------------------------------

  if (loading)
    return (
      <Navbar.Text>
        <UILoading />
      </Navbar.Text>
    )

  if (data && data.clientsByLaboratory) {
    const handleUpdateClient = (id) => {
      updateClient({ variables: { id } })
    }

    // This is a really ugly workaround
    const selectedClient = data.clientsByLaboratory.data.filter(
      ({ id, name }) => id === parseInt(me.id_client),
    )
    const { name } =
      selectedClient.length > 0
        ? selectedClient[0]
        : data.clientsByLaboratory.data[0]
    // --------------------------------

    switch (me.id_role) {
      // Root
      case 1:
      // Administrador
      case 2:
      // Analista
      case 3:
      // Ingeniero
      case 4:
        return (
          <>
            <Navbar.Text>Cliente:</Navbar.Text>
            <NavDropdown
              title={<strong>{name}</strong>}
              id="basic-nav-dropdown"
              className="mr-3"
            >
              {data.clientsByLaboratory.data.map(({ id, name }, index) => (
                <NavDropdown.Item
                  key={index}
                  onClick={() => handleUpdateClient(id)}
                  className="my-0 py-0"
                >
                  <small>{name}</small>
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </>
        )
      // Cliente
      case 5:
      // Muestrista
      case 6:
      // Muestrista + cliente
      case 7:
      // Buscador de muestras
      case 8:
      // Inspector
      case 9:
      // Teck dashboard
      case 10:
        return (
          <>
            <Navbar.Text>Cliente:</Navbar.Text>
            <Navbar.Text className="mr-3 ml-1">
              <strong>{name}</strong>
            </Navbar.Text>
          </>
        )
      default:
        return <Navbar.Text>error</Navbar.Text>
    }
  }

  return <Navbar.Text>error</Navbar.Text>
}

// -------------------------------------------------------------------------
//
// User profile
//
// -------------------------------------------------------------------------

const Profile = () => {
  const [{ me }] = useGlobal()

  return (
    <Navbar.Text className="mr-4">
      Usuario:{' '}
      <strong>
        <Link className="text-decoration-none" to={'/profile'}>
          {me && me.name}
        </Link>
      </strong>
    </Navbar.Text>
  )
}

// -------------------------------------------------------------------------
//
// SignOut button
//
// -------------------------------------------------------------------------

const SignOut = withApollo(({ client }) => {
  const [, dispatch] = useGlobal()
  // https://stackoverflow.com/questions/48887480/reset-store-after-logout-with-apollo-client

  const [SignOut] = useMutation(SIGN_OUT, {
    onCompleted: (data) => {
      dispatch({ type: 'updateMe', me: null })
      client.clearStore()
    },
  })

  const handleSignOut = () => {
    SignOut()
  }

  return (
    <Nav.Link onClick={handleSignOut}>
      <FontAwesomeIcon icon={faSignOutAlt} /> Salir
    </Nav.Link>
  )
})

// -------------------------------------------------------------------------
//
// Main render
//
// -------------------------------------------------------------------------

const UIWrapperPageFrameNavigationBarMenu = ({ toggled }) => {
  const [{ me }, dispatch] = useGlobal()

  return (
    <>
      <Navbar.Toggle
        bsPrefix="btn btn-stng navbar-toggler"
        aria-controls="NavBarToggleMenu"
      >
        <FontAwesomeIcon icon={faBars} />
      </Navbar.Toggle>
      <Navbar.Collapse
        id="NavBarToggleMenu"
        className="justify-content-end text-weight-light"
      >
        <Nav style={{ fontSize: '15px' }}>
          <ClientDropdown me={me} dispatch={dispatch} />
          <LaboratoryDropdown me={me} dispatch={dispatch} />
          <Profile />
          <SignOut />
        </Nav>
      </Navbar.Collapse>
    </>
  )
}

export default UIWrapperPageFrameNavigationBarMenu
