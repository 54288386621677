import React from 'react'
import { Link } from 'react-router-dom'
import { Badge, Col, Row } from 'react-bootstrap'
import moment from 'moment'

// Hooks
import { useEffect, useState, useQuery, useGlobal } from '../../../../hooks'

// Components
import UIEmpty from '../../../UI/Empty'
import UILoading from '../../../UI/Loading'
import UIError from '../../../UI/Error'
import UIPagination from '../../../UI/Pagination'
import UITable from '../../../UI/Table'
import UITableBody from '../../../UI/Table/Body'
import UITableHead from '../../../UI/Table/Head'
import UIForm from '../../../UI/FormReload'
import UIButton from '../../../UI/Button'
import UIFormInputSelect from '../../../UI/FormReload/Input/Select/'

// Queries
import { LIST_GENERATED_INSPECTIONS, LIST_SYSTEMS } from './queries'

const InspectionsFieldList = ({ showCreateForm, setShowCreateForm }) => {
  const [{ me }] = useGlobal()

  const [filterBySystemId, setFilterBySystemId] = useState(0)

  const [pagination, setPaginationState] = useState({
    limit: 25,
    page: 1,
    idSite: 0,
  })

  // --------------------------------------------------------------------------
  //
  // Query: Received samples
  //
  // --------------------------------------------------------------------------

  const { data, loading, refetch } = useQuery(LIST_GENERATED_INSPECTIONS, {
    fetchPolicy: 'network-only',
    variables: { ...pagination, id_system: filterBySystemId },
    onSuccess: ({ pagination }) => {
      setPaginationState((prev) => ({ ...prev, ...pagination }))
    },
    onErrorMessage: `No se pudo obtener la lista de inspecciones.`,
  })

  useEffect(() => {
    refetch()
  }, [me.laboratory, showCreateForm])

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  // Loading
  if (loading) return <UILoading />

  // Data
  if (data && data.i3InspectionsByStage && data.i3InspectionsByStage.data) {
    const { inspections } = data.i3InspectionsByStage.data
    const pagination = data.i3InspectionsByStage.pagination

    const handlePageChange = (page) => {
      setPaginationState((prev) => ({ ...prev, ...pagination, page }))
    }

    const handleFilterBySystemsId = (event) => {
      setFilterBySystemId(parseInt(event.target.value))
    }

    return (
      <UIForm>
        <Row className="m-0 mb-4">
          <Col className="text-left">
            <Badge
              className="px-2 py-2"
              variant="secondary"
              style={{ fontSize: '11pt' }}
            >
              Total inspecciones: {inspections.length}
            </Badge>
          </Col>
          <Col className="text-left">
            <UIFormInputSelect
              name="id_system"
              style={{ maxWidth: '200px' }}
              query={LIST_SYSTEMS}
              onChange={handleFilterBySystemsId}
              value={filterBySystemId}
            >
              <option value={0}>Filtrar sistema</option>
            </UIFormInputSelect>
          </Col>
        </Row>
        {inspections.length > 0 ? (
          <>
            <UITable>
              <UITableHead>
                {[' ', 'Tipo de inspección', 'Faena', 'Equipo'].filter(
                  (h) => !!h,
                )}
              </UITableHead>
              <UITableBody>
                {inspections.map((inspection) => (
                  <tr key={inspection.id}>
                    <td style={{ width: '120px' }}>
                      <Link
                        className={`text-decoration-none text-stng-darker`}
                        to={`/inspections/report/${inspection.id}`}
                      >
                        <UIButton
                          variant="stng"
                          style={{ width: '100px' }}
                          className="btn-sm p-1"
                        >
                          Abrir
                        </UIButton>
                      </Link>
                    </td>
                    {/*<td>{inspection.id}</td>*/}
                    <td>{inspection.type}</td>
                    <td>{inspection.site}</td>
                    <td>{inspection.machine}</td>
                    {/*<td>
                      {inspection.date_creation &&
                        moment(inspection.date_created).format('DD/MM/YYYY')}
                    </td>*/}
                  </tr>
                ))}
              </UITableBody>
            </UITable>
            <UIPagination pagination={pagination} onClick={handlePageChange} />
          </>
        ) : (
          <UIEmpty message="No hay nuevas inspecciones" />
        )}
      </UIForm>
    )
  }

  // Error
  return <UIError />
}
export default InspectionsFieldList
