import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

// Components
import UILoading from '../../../UI/Loading'
import UIError from '../../../UI/Error'
import UIPanelInfo from '../../../UI/Panel/Info'
import UIPanelInfoBlock from '../../../UI/Panel/Info/Block'
import UIPanelInfoItem from '../../../UI/Panel/Info/Item'

// Global reducer
import { useQuery } from '../../../../hooks'

// Queries
import { GET_INSPECTION_INFO } from './queries'

const InspectionsDesktopInfo = ({ id }) => {
  // --------------------------------------------------------------------------
  //
  // Query get sidepanel info
  //
  // --------------------------------------------------------------------------

  const { data, loading } = useQuery(GET_INSPECTION_INFO, {
    variables: { id },
    onErrorMessage: `Hubo un error y no se pudo obtener la información de la inspección`,
  })

  if (loading) return <UILoading />

  if (data && data.i2InspectionInfo) {
    const { user, machine, site, client, type } = data.i2InspectionInfo.data

    return (
      <UIPanelInfo>
        <UIPanelInfoBlock title="Datos inspección">
          <UIPanelInfoItem name="N° de inspección">{id}</UIPanelInfoItem>
          <UIPanelInfoItem name="Inspector">{user.name}</UIPanelInfoItem>
          <UIPanelInfoItem name="Técnica">{type.name}</UIPanelInfoItem>
        </UIPanelInfoBlock>

        <UIPanelInfoBlock title="Cliente">
          <UIPanelInfoItem name="Nombre">{client.name}</UIPanelInfoItem>
          <UIPanelInfoItem name="Faena">{site.name}</UIPanelInfoItem>
        </UIPanelInfoBlock>

        <UIPanelInfoBlock title="Equipo">
          <UIPanelInfoItem name="Nombre">{machine.name}</UIPanelInfoItem>
          <UIPanelInfoItem name="Tipo">{machine.type.name}</UIPanelInfoItem>
          <UIPanelInfoItem name="Marca">{machine.brand.name}</UIPanelInfoItem>
          <UIPanelInfoItem name="Modelo">{machine.model.name}</UIPanelInfoItem>
          {/*<UIPanelInfoItem name="N° de serie">???</UIPanelInfoItem>*/}
        </UIPanelInfoBlock>
      </UIPanelInfo>
    )
  }

  return <UIError />
}

export default InspectionsDesktopInfo
