// import React from 'react'
// import { Line } from 'react-chartjs-2'
import Chartjs from 'chart.js'

// Sexto gráfico
//
// Tipo 1: Sodio/Potasio

const Graphs = ({ ref, graphs, config, type }) => {
  const graph0 = graphs && graphs[13]
  const graph1 = graphs && graphs[14]

  // --------------------------------------------------------------------------
  //
  // No data
  //
  // --------------------------------------------------------------------------

  if (!graph0 && !graph1) {
    new Chartjs(ref.current, {
      type: 'line',
      labels: [1],
      data: {
        datasets: [
          {
            data: null,
          },
        ],
      },
      options: { ...config.options, legend: { display: false } },
    })

    return
  }

  const { x: x00, y: y00 } = graph0[0]
  const { x: x01, y: y01 } = graph0[graph0.length - 1]
  const { x: x10, y: y10 } = graph1[0]
  const { x: x11, y: y11 } = graph1[graph1.length - 1]

  const minX = Math.min(x00, x10)
  const maxX = Math.max(x01, x11)
  const minY = Math.min(y00, y10)
  const maxY = Math.max(y01, y11)

  // --------------------------------------------------------------------------
  //
  // The 4 graphs
  //
  // --------------------------------------------------------------------------

  // Get the larget one
  const graph = [
    { x: x01, graph: graph0 },
    { x: x11, graph: graph1 },
  ].sort((a, b) => b.x - a.x)[0].graph

  const { lic, lim, lsm, lsc } = graphs

  const labels =
    graph.length === 1 ? ['', graph[0].h, ''] : graph.map(({ h }) => h)

  new Chartjs(ref.current, {
    type: 'line',
    data: {
      labels,
      datasets: [
        {
          label: 'Sodio',
          data: graph0.map(({ h, y, label }) => ({
            h,
            y,
            label,
          })),
          ...config.main0,
        },
        {
          label: 'Potasio',
          data: graph1.map(({ h, y, label }) => ({
            h,
            y,
            label,
          })),
          ...config.main1,
        },
      ],
    },
    options:
      graph.length === 1
        ? {
            ...config.options,
            scales: {
              xAxes: [{ ticks: { fontSize: 50 }, padding: 40 }],
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                  },
                  beginAtZero: false,
                  ticks: {
                    min: Math.min(minY, lic, lim, lsm, lsc) * 0.9,
                    max: Math.max(maxY, lic, lim, lsm, lsc) * 1.1,
                    fontSize: 50,
                    padding: 40,
                  },
                },
              ],
            },
          }
        : config.options,
    plugins: [
      {
        afterDatasetsDraw: function (chart) {
          var ctx = chart.ctx
          chart.data.datasets.forEach(function (dataset, index) {
            var datasetMeta = chart.getDatasetMeta(index)
            if (datasetMeta.hidden) return
            if (!dataset.showLabelsOverPoints) return

            datasetMeta.data.forEach(function (point, index) {
              const label = dataset.data[index].label

              if (!label) return

              var x = point.getCenterPoint().x,
                y = point.getCenterPoint().y,
                radius = point._model.radius,
                fontSize = 25,
                fontFamily = 'Sans',
                fontColor = '#228B22',
                fontStyle = 'bold'

              ctx.save()
              ctx.textBaseline = 'middle'
              ctx.textAlign = 'center'
              ctx.font = fontStyle + ' ' + fontSize + 'px' + ' ' + fontFamily
              ctx.fillStyle = fontColor
              ctx.fillText(label, x, y - radius - fontSize)
              ctx.restore()
            })
          })
        },
      },
    ],
  })
}

// Graphs
//
// Tipo 3: Cogido ISO (clases que concatenen los dos ultimos valores)
// Tipo 4: Cogido ISO (clases que concatenen los dos ultimos valores)

const GraphsISO = ({ ref, graphs, config, type }) => {
  const graph0 = graphs && graphs[44]
  const graph1 = graphs && graphs[45]

  // --------------------------------------------------------------------------
  //
  // No data
  //
  // --------------------------------------------------------------------------

  if (!graph0 && !graph1) {
    new Chartjs(ref.current, {
      type: 'line',
      labels: [1],
      data: {
        datasets: [
          {
            data: null,
          },
        ],
      },
      options: { ...config.options, legend: { display: false } },
    })

    return
  }

  const { x: x00, y: y00 } = graph0[0]
  const { x: x01, y: y01 } = graph0[graph0.length - 1]
  const { x: x10, y: y10 } = graph1[0]
  const { x: x11, y: y11 } = graph1[graph1.length - 1]

  const minX = Math.min(x00, x10)
  const maxX = Math.max(x01, x11)
  const minY = Math.min(y00, y10)
  const maxY = Math.max(y01, y11)

  // --------------------------------------------------------------------------
  //
  // The 4 graphs
  //
  // --------------------------------------------------------------------------

  // Get the larget one
  const graph = [
    { x: x01, graph: graph0 },
    { x: x11, graph: graph1 },
  ].sort((a, b) => b.x - a.x)[0].graph

  const labels = graph.length === 1 ? ['', 1, ''] : graph.map(({ x }) => x)

  new Chartjs(ref.current, {
    type: 'line',
    data: {
      labels,
      datasets: [
        {
          label: 'Partículas >4um',
          data: graph0.map(({ x, y, label }) => ({
            x,
            y,
            label,
          })),
          ...config.main0,
          showLabelsOverPoints: true,
        },
        {
          label: 'Partículas >6um',
          data: graph1.map(({ x, y, label }) => ({
            x,
            y,
            label,
          })),
          ...config.main1,
          showLabelsOverPoints: true,
        },
      ],
    },
    options:
      graph.length === 1
        ? {
            ...config.options,
            scales: {
              xAxes: [{ ticks: { fontSize: 50 }, padding: 40 }],
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                  },
                  beginAtZero: false,
                  ticks: {
                    min: minY * 0.9,
                    max: maxY * 1.1,
                    fontSize: 50,
                    padding: 40,
                  },
                },
              ],
            },
          }
        : config.options,
    plugins: [
      {
        afterDatasetsDraw: function (chart) {
          var ctx = chart.ctx
          chart.data.datasets.forEach(function (dataset, index) {
            var datasetMeta = chart.getDatasetMeta(index)
            if (datasetMeta.hidden) return
            if (!dataset.showLabelsOverPoints) return

            datasetMeta.data.forEach(function (point, index) {
              const label = dataset.data[index].label

              if (!label) return

              var x = point.getCenterPoint().x,
                y = point.getCenterPoint().y,
                radius = point._model.radius,
                fontSize = 25,
                fontFamily = 'Sans',
                fontColor = '#228B22',
                fontStyle = 'bold'

              ctx.save()
              ctx.textBaseline = 'middle'
              ctx.textAlign = 'center'
              ctx.font = fontStyle + ' ' + fontSize + 'px' + ' ' + fontFamily
              ctx.fillStyle = fontColor
              ctx.fillText(label, x, y - radius - fontSize)
              ctx.restore()
            })
          })
        },
      },
    ],
  })
}

const Graph6 = ({ ref, graphs, config, type }) => {
  //
  // Tipo 1: Sodio/Potasio
  // Tipo 2:
  // Tipo 3: Cogido ISO (clases que concatenen los dos ultimos valores)
  // Tipo 4: Cogido ISO (clases que concatenen los dos ultimos valores)

  switch (type) {
    case 1:
    case '1':
      Graphs({ ref, graphs, config, type })
      break
    // case 2:
    // case '2':
    case 3:
    case '3':
    case 4:
    case '4':
      GraphsISO({ ref, graphs, config, type })
      break
  }
}

export default Graph6
