import React from 'react'

// Components
import { Quicksight } from '../../UI/Dashboard/QuickSight'
import UILoading from '../../UI/Loading'
import UIError from '../../UI/Error'
import UIPanelInfo from '../../UI/Panel/Info'

// Hooks
import { useQuery } from '../../../hooks'

// Queries
import { QUICKSIGHT_URL } from './queries'

const DashboardCda3 = () => {
  const { data, loading } = useQuery(QUICKSIGHT_URL, {
    fetchPolicy: 'network-only',
  })

  if (loading)
    return (
      <UIPanelInfo>
        <UILoading />
      </UIPanelInfo>
    )

  if (data && data.teckDashboard3 && data.teckDashboard3.data) {
    const { Status, EmbedUrl } = data.teckDashboard3.data

    if (![200, '200'].includes(Status)) return <UIError />

    return <Quicksight url={EmbedUrl} />
  }

  return <UIError />
}

export default DashboardCda3
